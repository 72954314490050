import { Injectable } from '@angular/core';
import {IToken} from "../interfaces/token";


@Injectable({
  providedIn: 'root'
})
export class DataStorageService {

  private localStorageS;


  constructor() {
    this.localStorageS = localStorage;
  }

  GetUserEmail():string{
    const TOKEN: IToken = JSON.parse(atob(this.getStorage('CurrentSession')!));
      return TOKEN.userName;
  }

  GetUserId():number{
    let session:string | null = this.getStorage('CurrentSession');

    if(!session) return 0;
    const TOKEN: IToken = JSON.parse(atob(session!));
    return TOKEN.userId;
  }



  GetUserAccess(): string[] {
    const TOKEN: IToken = JSON.parse(atob(this.getStorage('CurrentSession')!));
    if(!TOKEN) return []
    const permiss :string[] = JSON.parse(TOKEN.permissions)
    return permiss;
  }

  deleteStorage(name: string): void {
    this.localStorageS.removeItem(name);
  }

  getStorage(name: string): string | null {
    return this.localStorageS.getItem(name) ?? null;
  }


}
