import {Component, Inject, OnInit} from '@angular/core';
import {IMenuNode} from '@clavisco/menu';
import {CL_CHANNEL, ICLCallbacksInterface, ICLEvent, LinkerService, Register, Run, StepDown} from '@clavisco/linker';
import {Router} from '@angular/router';
import {AuthenticationService} from '../services/authentication.service';
import {AssetsService} from '../services/assets.service';
import {GlobalService} from '../services/global.service';
import {IMenuItem} from '../interfaces/menu';
import {DataStorageService} from '../services/data-storage.service';
import {AlertsService, CLToastType} from '@clavisco/alerts';
import {UserService} from '../services/user.service';
import {forkJoin} from 'rxjs';
import {IUser} from '../interfaces/user';
import {MatDialog} from '@angular/material/dialog';
import {ModalUsersComponent} from './admin/users/modal-users/modal-users.component';
import {Repository, Structures} from '@clavisco/core';
import {MenuService} from '../services/menu.service';
import {environment} from '../../environments/environment';

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss']
})

export class PagesComponent implements OnInit {

  Toggle: boolean = true;

  MenuId: string = 'main-menu';
  MenuNodes: IMenuNode[] = [];
  LogoDefault: string = '../../assets/img/clavisco-tools-white.png';
  LogoSrc: string = this.LogoDefault;
  User: string = '';
  Title: string = environment.HomeTitle;
  CurrentPageTitle: string = '';
  CurrentModuleTitle: string = '';
  profileDefault: string = '../../assets/img/user.png';
  profile: string = this.profileDefault;
  selectedUser!: IUser;

  callbacks: ICLCallbacksInterface<CL_CHANNEL> = {
    Callbacks: {},
    Tracks: []
  };

  constructor (
    private router: Router,
    public assetService: AssetsService,
    private globalService: GlobalService,
    private authService: AuthenticationService,
    private dataStorage: DataStorageService,
    private alertService: AlertsService,
    private userService: UserService,
    private menuService: MenuService,
    public dialog: MatDialog,
    @Inject('LinkerService') private linkerervice: LinkerService
  ) {
    this.globalService.currentPageTitle.subscribe((next: string): void => {
      this.CurrentPageTitle = next;
    });

    this.globalService.currentModuleTitle.subscribe(next => this.CurrentModuleTitle = next);
    this.assetService.Menu.subscribe((next: IMenuItem[]): void => {
      next.map(x => {
        this.ValidateOptionMenu(x);
      });
      this.MenuNodes = next;
      this.linkerervice.Publish(
        {
          CallBack: CL_CHANNEL.INFLATE,
          Data: JSON.stringify(this.MenuNodes),
          Target: this.MenuId
        }
      );
    });

    this.menuService.MenuItem.subscribe((next: IMenuItem[]) => {
      this.MenuNodes = next as IMenuNode[];
      this.linkerervice.Publish(
        {
          CallBack: CL_CHANNEL.INFLATE,
          Data: JSON.stringify(next),
          Target: this.MenuId
        }
      );
    });

    this.menuService.CurrentPageTitle.subscribe((next) => {
      this.CurrentPageTitle = next;
    });

    this.globalService.UserAvatar.subscribe(value => {
      this.profile = value ? value : this.profileDefault;
    });
  }

  ValidateOptionMenu (node: IMenuItem): void {
    switch (node.Key) {
      case 'admin':
        if (!this.dataStorage.GetUserAccess().includes('M_Administration')) {
          node.Visible = false;
        }
        break;
      case 'tcm':
        if (!this.dataStorage.GetUserAccess().includes('M_TCM')) {
          node.Nodes.map(x => x.Visible = false);
        }
        break;
      case 'tmp':
        if (!this.dataStorage.GetUserAccess().includes('M_TMP')) {
          node.Nodes.map(x => x.Visible = false);
        }
        break;
      case 'acb':
        if (!this.dataStorage.GetUserAccess().includes('M_ACB')) {
          node.Nodes.map(x => x.Visible = false);
        }
        break;
      default:
        node.Nodes?.map(y => {
          if (!this.dataStorage.GetUserAccess().includes(y.Permission)) {
            y.Visible = false;
          }
        });
        break;
    }
  }

  ngOnInit (): void {
    const userId: number = this.dataStorage.GetUserId();

    Register(this.MenuId, CL_CHANNEL.OUTPUT, this.ClickMenuOption, this.callbacks);
    this.linkerervice.Flow()?.pipe(
      StepDown<CL_CHANNEL>(this.callbacks),
    ).subscribe({
      next: callback => Run(callback.Target, callback, this.callbacks.Callbacks),
      error: error => console.log(`Error CLAVIS:`, error)
    });

    this.OnLoadData(userId);
  }

  private OnLoadData (userId: number): void {
    forkJoin([this.userService.GetId(userId), this.menuService.Get()]).subscribe(
      ([userData, menuData]): void => {
        if (userData) {
          this.selectedUser = userData.Data;
          if (this.selectedUser.ProfilePicture) {
            this.globalService.UserAvatar.next(this.selectedUser.ProfilePicture!);
          }
        }
        if (menuData) {
          let menu: IMenuItem[] = menuData.Data;
          Repository.Behavior.SetStorage<IMenuItem[]>(menu, 'KeyMenu');
          this.MenuNodes = menu;
          this.menuService.MenuItem.next(menu);
          this.assetService.Menu.next(menu);
        }
      }
    );
  }

  ClickMenuOption = (_event: ICLEvent): void => {
    if (_event) {
      const NODE_OPTION: IMenuItem = JSON.parse(_event.Data);
      switch (NODE_OPTION.Key) {
        case 'tcm':
          if (!this.dataStorage.GetUserAccess().includes('M_TCM')) {
            this.alertService.Toast({
              message: 'No tienes acceso a esta sección, para continuar, comuniquese con administración',
              type: CLToastType.ERROR
            });
          }
          break;
        case 'tmp':
          if (!this.dataStorage.GetUserAccess().includes('M_TMP')) {
            this.alertService.Toast({
              message: 'No tienes acceso a esta sección, para continuar, comuniquese con administración',
              type: CLToastType.ERROR
            });
          }
          break;
        case 'acb':
          if (!this.dataStorage.GetUserAccess().includes('M_ACB')) {
            this.alertService.Toast({
              message: 'No tienes acceso a esta sección, para continuar, comuniquese con administración',
              type: CLToastType.ERROR
            });
          }
          break;
        case 'exit':
          this.authService.Logout();
          this.router.navigateByUrl('/Login');
          break;
      }
    }
  };

  ToggleMenu (): void {
    this.Toggle = !this.Toggle;
    this.linkerervice.Publish({
      CallBack: CL_CHANNEL.DATA_LINE_1,
      Target: this.MenuId,
      Data: JSON.stringify(this.Toggle)
    });
  }

  OpenUserDialog (): void {
    if (this.selectedUser) {
      this.dialog.open(ModalUsersComponent, {
        width: '550px',
        data: this.selectedUser
      });
    } else {
      this.alertService.Toast({message: `No se pudo obtener la información del usuario`, type: CLToastType.WARNING});
    }
  }
}
