import {Injectable} from "@angular/core";
import {DataStorageService} from "./data-storage.service";
import {Router} from "@angular/router";


@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {


  constructor(
    private dataStorageService: DataStorageService,
    private router: Router,
  ) {
  }

  public IsActhenticated(): boolean {
    if (this.dataStorageService.getStorage('CurrentSession') === null) return false
    const TOKEN = JSON.parse(atob(this.dataStorageService.getStorage('CurrentSession')!));
    if (TOKEN) {
      var expires = new Date(TOKEN.expires);
      if (this.GetUTCNow() <= expires) {
        return true;
      }
    }
    return false;
  }

  private GetUTCNow(): Date {
    const dateNow: Date = new Date();
    return new Date(dateNow.getUTCFullYear(), dateNow.getUTCMonth(), dateNow.getUTCDate(), dateNow.getUTCHours(), dateNow.getUTCMinutes(), dateNow.getUTCSeconds());
  }

  Logout(): void {
    try {
      this.dataStorageService.deleteStorage('CurrentSession');
    } catch (error) {
    }
  }
}
