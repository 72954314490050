import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest, HttpStatusCode} from '@angular/common/http';
import {catchError, map, Observable, throwError} from 'rxjs';
import {DataStorageService} from '../services/data-storage.service';
import {AuthenticationService} from '../services/authentication.service';
import {Router} from '@angular/router';
import {environment} from '../../environments/environment';
import {AlertsService, CLToastType, ModalService} from '@clavisco/alerts';
import {MessageError} from "../interfaces/token";

@Injectable()
export class AppInterceptor implements HttpInterceptor {
  constructor (
    private dataStorageService: DataStorageService,
    private authService: AuthenticationService,
    private router: Router,
    private alertService: AlertsService,
    private modalService: ModalService
  ) {
  }

  readonly UnauthorizedMessage: string[] = [
    'Su sesión pudo haber expirado, intente iniciando nuevamente',
    'Datos de licencia de sap incorrectos',
    'No cuenta con permisos para realizar esta acción'
  ];

  intercept (req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (req.url.includes('assets') || req.url.includes('token') || req.url.includes('ChangeRecoverPassword') || req.url.includes('RecoveryPassword') || req.url.includes('ChangePassword')) {
      return next.handle(req).pipe(
        catchError((error: any) => {
          return throwError(() => {
            if (error?.error?.code === HttpStatusCode.Unauthorized) {
              return error = error.error.message;
            }
            return error;
          });
        }));
    }

    req = this.AddAuthentication(req);
    let clonedRequest: HttpRequest<unknown> = req.clone();

    // Valido que no tenga el header que indica si tiene archivos para no enviar el content type
    if (!clonedRequest.headers.has('Request-With-Files')) {
      let headers = clonedRequest.headers.set('Content-Type', 'application/json');
      clonedRequest = clonedRequest.clone({headers});
    }
    clonedRequest = clonedRequest.clone({url: `${environment.ApiUrl}${req.url}`});

    return next.handle(clonedRequest).pipe(
      map((event: HttpEvent<any>) => {
        return event;
      }),
      catchError((error: any) => {
        const messageError: MessageError = this.ShowMessageError(error);
        if(messageError.InModal){
          this.modalService.NextError(
            {
              title: messageError.Message
            }
          )
        } else {
          this.alertService.Toast({message: messageError.Message, type: CLToastType.ERROR});
        }
        throw new Error(messageError.Message);
      })
    );
  }

  ShowMessageError (error: any): MessageError {
    var message: string = '';
    let messageError: MessageError = { Message: "", InModal: false};
    switch (error['status']) {
      case 0:
        message = 'Sin conexión con el servidor';
        break;
      case HttpStatusCode.Forbidden:
        message = 'No tiene permiso para realizar la petición';
        break;
      case HttpStatusCode.InternalServerError:
        if (error.hasOwnProperty('error') && error.error.hasOwnProperty('Message')) {
          message = error.error.Message;
        } else {
          message = error['error'];
        }
        break;
      case HttpStatusCode.Unauthorized:
        message = this.UnauthorizedMessage.join(', ');
        messageError.InModal = true
        if (!this.authService.IsActhenticated()) {
          this.authService.Logout();
          this.router.navigateByUrl('/Login');
        }
        break;
      default:
        if (error.hasOwnProperty('error') && error.error.hasOwnProperty('Message')) {
          message = error.error?.Message;
        } else {
          message = error.error?.title;
        }
        break;
    }
    messageError.Message = message;
    return messageError;
  }

  private AddAuthentication (request: HttpRequest<any>): HttpRequest<any> {
    const TOKEN = JSON.parse(atob(this.dataStorageService.getStorage('CurrentSession')!));

    if (!TOKEN) {
      return request;
    }

    if (!this.authService.IsActhenticated()) {
      this.authService.Logout();
      this.router.navigateByUrl('/Login');
      throw new Error('No está autenticado, por favor inicie sesión');
    }

    let headers: HttpHeaders = request.headers.set(`Authorization`, `Bearer ${TOKEN.access_token}`);

    request = request.clone({headers});
    return request;
  }
}
