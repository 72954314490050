import {Component, OnInit} from "@angular/core";
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login-container',
  templateUrl: './login-container.component.html',
  styleUrls: ['./login-container.component.scss']
})
export class LoginContainerComponent implements OnInit {

  LoginId: string = 'main-login';
  ShouldResolve: boolean = true;
  ApiUrl: string = `${environment.ApiUrl}api/`;
  PathToRedirect: string ="Home";
  SesionName: string ="CurrentSession";
  LogoPath: string ="../../../assets/img/clavis-blue.png";
  DotNetApiType: 'CORE' = 'CORE';
  useReCaptcha: boolean = true

  constructor() {

  }

  ngOnInit() {

  }

}
