<cl-menu
  [Id]="MenuId"
  [Logo]="LogoSrc"
  [Title]="Title"
  [User]="User"
  [Nodes]="MenuNodes">
  <div class="app-container">
    <mat-toobar>
      <div class="header-container" style="background-color: white;">
        <div class="title-container">
          <button class="ml-1 mb-1 mt-1" mat-icon-button (click)="ToggleMenu()">
            <mat-icon>menu</mat-icon>
          </button>
          <div class="ml-1 page-title">
            <h3>{{CurrentPageTitle}}</h3>
          </div>
        </div>

        <div *ngIf="selectedUser">
          <header class="header-row">
            <span class="header-divider section-element">
              <b class="mr-1">
                Sección:
              </b>
              <b class="title-color">
                {{CurrentModuleTitle}}
              </b>
            </span>
            <span class="header-divider">
              <button type="button" mat-flat-button (click)="OpenUserDialog()">
              <img [src]="profile" alt="Foto perfil" class="Avatar mr-1">
              <span class="">
                Usuario:
                <b class="title-color">
                  {{selectedUser.Email}}
                </b>
              </span>
            </button>
            </span>
            <span class="notification-button">
              <cl-notification-center></cl-notification-center>
            </span>
          </header>
        </div>
      </div>
    </mat-toobar>
    <router-outlet name="primary"></router-outlet>
  </div>
</cl-menu>
