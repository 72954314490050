<div>
  <h3>{{action}} Usuario</h3>
  <div>
    <form [formGroup]="formUser" (ngSubmit)="SaveUser()" class="mt-1 container-form">
      <div class="center" fxflex="100" fxFlex.xs="95" fxFlex.sm="50" fxFlex.gt-sm="15">
        <mat-card class="mat-elevation-z0s">
          <mat-icon class="icon-delete" (click)="ClearPicture()">delete</mat-icon>
          <label class="hoverable ml-2" for="fileInput">
            <img id="imgProfile" [src]="profilePicture" alt="Foto perfil" class="profilePicture"
                 accept="image/*">
            <mat-icon class="icon">add_a_photo</mat-icon>
          </label>
          <input accept=".png,.jpeg, .jpg" id="fileInput" type='file' (change)="OnSelectFile($event)"
                 onclick="this.value=null;">
        </mat-card>
      </div>
      <mat-form-field appearance="outline" class="w-100 mt-1 ">
        <mat-label>Nombre</mat-label>
        <input matInput placeholder="Nombre" formControlName="Name">
        <mat-error>
          El nombre es requerido.
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" class="w-100 mt-1">
        <mat-label>Apellido</mat-label>
        <input matInput placeholder="Apellido" formControlName="LastName">
        <mat-error>
          El apellido es requerido.
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" class="w-100 mt-1">
        <mat-label>Correo</mat-label>
        <input matInput placeholder="Correo" formControlName="Email">
        <mat-error>
          El correo es requerido.
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" class="w-100 mt-1">
        <mat-label>Contraseña</mat-label>
        <input [type]="hidePassword ? 'password' : 'text'" matInput placeholder="Contraseña" formControlName="Password"
               #InputPassword (ngModelChange)="OnInputChange($event)">
        <button type="button" mat-icon-button matSuffix (click)="TogglePasswordVisibility()">
          <mat-icon>{{ hidePassword ? 'visibility_off' : 'visibility' }}</mat-icon>
        </button>
        <mat-error>
          La contraseña es requerida y debe ser mayor o igual a 8 caracteres.
        </mat-error>
      </mat-form-field>
      <mat-slide-toggle formControlName="IsActive">Estado</mat-slide-toggle>
      <div fxLayoutAlign="end">
        <button type="button" mat-stroked-button color="secondary" [mat-dialog-close]="null">Cancelar</button>
        <button class="ml-2" mat-flat-button color="primary" type="submit"
                [disabled]="!formUser.valid">{{action}}</button>
      </div>

    </form>

  </div>

</div>
