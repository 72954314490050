import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpResponse, HttpHeaders
} from '@angular/common/http';
import {catchError, filter, Observable, tap, throwError} from 'rxjs';
import {CLNotificationType, NotificationCenterService} from "@clavisco/notification-center";
import {Structures} from "@clavisco/core";
import ICLResponse = Structures.Interfaces.ICLResponse;

@Injectable()
export class ActionCenterPushInterceptor implements HttpInterceptor {

  constructor(private notificationCenterService: NotificationCenterService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if(request.url.includes('token')) return next.handle(request);
    return next.handle(request).pipe(
      filter(response => response instanceof HttpResponse),
      tap(response => {
        if(request.method !== 'GET' && (request.headers.get('Cl-Request-Success-Description') && request.headers.get('Cl-Request-Success-Description') != "")){
          this.notificationCenterService.Push({
            type: CLNotificationType.SUCCESS,
            priority: 'low',
            title: request.headers.get('Cl-Request-Success-Description') || this.CutURL(request.url),
            message: (response['headers' as keyof object] as HttpHeaders)?.get('cl-message') || (response["body" as keyof object] as ICLResponse<any>)?.Message || ''
          });
        }
      }),
      catchError((error: any) => {
        this.notificationCenterService.Push({
          type: CLNotificationType.ERROR,
          priority: 'high',
          title:  request.headers.get('Cl-Request-Error-Description') || this.CutURL(request.url),
          message: (error['headers' as keyof object] as HttpHeaders)?.get('cl-message') ?? error.error.Message ?? "Error"
        });
        return throwError(() => error);
      })
    )
  }

  private CutURL(_url:string): string
  {
    let startIndex = _url.indexOf('api');
    let endIndex = _url.indexOf("?");

    return _url.substring(startIndex, endIndex === -1 ? _url.length : endIndex);
  }
}
