import {Injectable} from '@angular/core';
import {tap, catchError, switchMap} from 'rxjs/operators';
import {Subject} from 'rxjs/internal/Subject';
import {IMenuItem} from "../interfaces/menu";
import {AssetsService} from "./assets.service";
import {MenuService} from "./menu.service";
import {Structures} from "@clavisco/core";
import ICLResponse = Structures.Interfaces.ICLResponse;


@Injectable({
  providedIn: 'root'
})
export class GlobalService {
  public currentPageTitle: Subject<string>;
  public currentModuleTitle: Subject<string>;
  public UserAvatar: Subject<string>;
  public src: string = "";

  public tempMenu: IMenuItem[] = [];

  constructor(
    private assetService: AssetsService,
    private menuService: MenuService
  ) {
    this.currentPageTitle = new Subject<string>();
    this.currentModuleTitle = new Subject<string>();
    this.UserAvatar = new Subject<string>();
  }


  GetCurrentViewName(path: string): string {
    return this.FindDescriptionRecursively(this.tempMenu, path);
  }

  FindDescriptionRecursively(menu: IMenuItem[], path: string): string {
    for (const node of menu) {
      if (node.Route === path) {
        return node.Description;
      }
      if (node.Nodes && node.Nodes.length > 0) {
        const description = this.FindDescriptionRecursively(node.Nodes, path);
        if (description) {
          return description;
        }
      }
    }
    return "";
  }

  setMenuOptions(path: string): void {
    this.menuService.Get().pipe(
      tap((data: ICLResponse<IMenuItem[]>): void => {
        if (data.Data) {
          const filterMenu: IMenuItem[] = data.Data.filter(x => x.Visible)
          this.assetService.Data.menu = filterMenu;
          this.assetService.Menu.next(filterMenu);
          this.tempMenu = filterMenu;
          this.currentPageTitle.next(this.GetCurrentViewName(path))
        }
      }),
      catchError(async () => this.setMenuOptions(path))
    ).subscribe();
  }

  FindNodeByRoute(menu: IMenuItem[], path: string): IMenuItem | null {
    for (const node of menu) {
      if (node.Route === path && node.Route != 'Admin') {
        return node;
      }
      if (node.Nodes && node.Nodes.length > 0) {
        const foundNode = this.FindNodeByRoute(node.Nodes, path);
        if (foundNode) {
          return foundNode;
        }
      }
    }
    return null; // Devuelve null si no se encuentra el nodo
  }


}

