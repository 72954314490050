import { Injectable } from '@angular/core';
import {Router} from "@angular/router";
import {Subject, Subscription, tap} from "rxjs";
import {IActionButton} from "../interfaces/TMP/action-button";
import {HttpHeaders} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  private readonly actionButtonClicked$: Subject<IActionButton>;

  constructor(private router: Router) {
    this.actionButtonClicked$ = new Subject<IActionButton>();
  }

  GetCurrentRouteSegment(): string {
    return this.router.url.split('?').shift() || '/';
  }

  OnActionButtonClicked(_func: (_actionButton: IActionButton) => void): Subscription {
    return this.actionButtonClicked$.asObservable()
      .pipe(
        tap(_func)
      )
      .subscribe();
  }

  DefineDescriptionHeader(_descriptions: { OnError: string, OnSuccess?: string, OnDetail?: string}, _headers: HttpHeaders | null | undefined = undefined): HttpHeaders
  {
    if(_headers)
    {
      return _headers.set('Cl-Request-Error-Description', _descriptions.OnError).set('Cl-Request-Success-Description', _descriptions.OnSuccess??"").set('Cl-Request-Detail-Description', _descriptions.OnDetail??"");
    }
    return new HttpHeaders().set('Cl-Request-Error-Description', _descriptions.OnError).set('Cl-Request-Success-Description', _descriptions.OnSuccess??"").set('Cl-Request-Detail-Description', _descriptions.OnDetail??"");
  }
}
