import {Injectable} from '@angular/core';
import {IMenuItem} from '../interfaces/menu';
import {Subject} from 'rxjs/internal/Subject';
import {Repository, Structures} from '@clavisco/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import ICLResponse = Structures.Interfaces.ICLResponse;

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  public MenuItem: Subject<IMenuItem[]>;
  public CurrentPageTitle: Subject<string>;

  private readonly CONTROLLER: string = 'api/Menu';

  constructor (
    private http: HttpClient
  ) {
    this.MenuItem = new Subject<IMenuItem[]>();
    this.CurrentPageTitle = new Subject<string>();
  }

  Get (): Observable<ICLResponse<IMenuItem[]>> {
    return this.http.get<ICLResponse<IMenuItem[]>>(`${this.CONTROLLER}`);
  }

  SetCurrentPageTitle (path: string): void {
    path = path.startsWith('/') ? path.substring(1) : path;
    this.CurrentPageTitle.next(this.GetCurrentViewName(path));
  }

  GetCurrentViewName (path: string): string {
    return this.FindDescriptionRecursively(this.GetLocalMenu(), path);
  }

  FindDescriptionRecursively (menu: IMenuItem[], path: string): string {
    if (menu != null) {
      for (const node of menu) {
        if (node.Route === path) {
          return node.Description;
        }
        if (node.Nodes && node.Nodes.length > 0) {
          const description: string = this.FindDescriptionRecursively(node.Nodes, path);
          if (description) {
            return description;
          }
        }
      }
    }
    return '';
  }

  GetLocalMenu(): IMenuItem[] {
    return Repository.Behavior.GetStorageObject<IMenuItem[]>('KeyMenu') as IMenuItem[];
  }
}

