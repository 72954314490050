import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import {AlertsService, CLToastType} from '@clavisco/alerts';
import {Observable} from 'rxjs';
import {AuthenticationService} from '../services/authentication.service';
import {DataStorageService} from '../services/data-storage.service';
import {GlobalService} from '../services/global.service';
import {MenuService} from '../services/menu.service';
import {IMenuItem} from "../interfaces/menu";
import {Repository} from "@clavisco/core";

@Injectable({
  providedIn: 'root'
})

export class AuthGuard implements CanActivate {

  constructor (
    public auth: AuthenticationService,
    private router: Router,
    public dialog: MatDialog,
    private alertService: AlertsService,
    private dataStorage: DataStorageService,
    private globalService: GlobalService,
    private menuService: MenuService,
  ) {
  }

  canActivate (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const path: string = state.url.substring(1);
    if (this.auth.IsActhenticated()) {
      if (this.menuService.GetLocalMenu()) {
        const node = this.globalService.FindNodeByRoute(this.menuService.GetLocalMenu(), path);
        if (node) {
          if (!this.dataStorage.GetUserAccess().includes(node.Permission) && path != 'Home' && !this.dataStorage.GetUserAccess().includes('M_Administration')) {
            this.alertService.Toast({
              message: 'No tienes acceso a esta sección, para continuar, comuniquese con administración',
              type: CLToastType.ERROR,
              duration: 2000
            });
            this.router.navigate(['/Home']);
            return false;
          }
        } else {
          this.alertService.Toast({
            message: 'No tienes acceso a esta sección, para continuar, comuniquese con administración',
            type: CLToastType.ERROR,
            duration: 2000
          });
          this.router.navigate(['/Home']);
          return false;
        }
      }
      const actualPath: string = this.GetRouteWithoutParams(route);
      this.menuService.SetCurrentPageTitle(actualPath);
      return true;
    } else {
      this.alertService.Toast({message: 'No está autenticado, por favor inicie sesión', type: CLToastType.ERROR});
      this.auth.Logout();
      this.router.navigate(['/Login']);
      return false;
    }
  }

  private GetRouteWithoutParams (route: ActivatedRouteSnapshot): string {
    const urlSegments: string[] = route.url.map(segment => segment.path);
    if (urlSegments.length > 0) {
      return urlSegments[0];
    }
    return '';
  }

}
